.bottom-bar * {
  margin: 0;
}

.bottom-bar {
  position: sticky;
  bottom: 0px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;

  height: 88px;
  width: 100%;
  gap: 16px;
  padding: 0px 16px 24px 16px;
  
  border-top: 1px solid #BDBDBD;
  background-color: white;
}

.bottom-bar .nav-element {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  align-items: center;
  width: 33%;
  height: 100%;

  font-family: "Quicksand", sans-serif;
  font-size: 14px;
  line-height: 17.5px;
  font-weight: 700;

  cursor: pointer;
}

.bottom-bar .nav-element-selected {
  box-shadow: inset 0 2px 0 0 #B84F28;
  color: #B84F28;
}

.bottom-bar .nav-element-selected svg path {
  fill: #B84F28;
}

