.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto !important;
  background-color: #FDFBFD;

  @media (min-width: 1000px) {
    border-left: 1px solid #BDBDBD;
    border-right: 1px solid #BDBDBD;
  }
}

.loading-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: calc(100vh - 150px);
}

.loading-header {
  font-size: 20px;
  line-height: 25px;
  font-weight: 700;
  margin-bottom: 30px;
}

.loading-animation {
  height: 300px;
  clip-path: inset(0 0 5px 0);
}

.loading-buttons {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  position: absolute;
  box-sizing: border-box;
  padding-right: 16px;
  bottom: 16px;
  margin-bottom: 16px;
  right: 0px;

  @media (min-width: 1000px) {
    right: calc(50% - 500px);
  }
}

.loading-cancel-button {
  width: 120px;
  height: 44px;
  padding: 0px 16px;
  margin-bottom: 16px;
  border-radius: 100px;
  align-self: flex-end;
  margin-right: 16px;

  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: none;

  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;

  background-color: white;
  color: #121212;
  box-shadow: 0px 4px 8px 0px #00000040;
}

.loading-status {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 240px;
  height: 56px;
  padding: 0px 16px;
  border-radius: 100px;
  align-self: flex-end;
  margin-right: 16px;

  text-align: center;
  text-decoration: none;
  border: none;

  font-family: "Quicksand", sans-serif;
  font-size: 18px;
  line-height: 22.5px;
  font-weight: 600;

  color: #B84F28;
  background-color: #FFE1CC;
  box-shadow: 0px 4px 8px 0px #00000040;
}

.loading-spinner {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
