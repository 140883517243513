.preferences-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto !important;
  height: unset !important;
  width: 100%;
  max-width: 1000px;

  @media (min-width: 1000px) {
    border-left: 1px solid #BDBDBD;
    border-right: 1px solid #BDBDBD;
    min-height: 100vh;
    height: auto !important;
  }
}

.preferences-container .header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 70px;
  padding: 20px 16px 20px 20px;
  border-bottom: 1px solid #BDBDBD;
  z-index: 2;
  background-color: white;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
}

.ingredient-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  align-self: flex-start;
}

.preferences-tips-image {
  display: block;
  object-fit: contain;
  margin: 0 16px;
  height: 130px;
  max-width: 100%;
}

.serving-size-container, .items-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: calc(100% - 32px);
  margin: 40px 16px 16px 16px;
}

.serving-size-control-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: #ececec;

  width: 100%;
  padding: 12px 40px 12px 40px;
  border-radius: 12px;
  margin-top: 12px;
}

.serving-size-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 44px;
  height: 44px;
  border-radius: 50%;

  background-color: white;
  cursor: pointer;
  border: 1px solid black;
}

.serving-size-display {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 64px;
  height: 64px;
  border-radius: 12px;

  font-size: 24px;
  line-height: 30px;
  font-weight: 700;

  background-color: white;
  border: 1px solid #6b6b6b;
}

.item-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.item-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  width: 100%;
  border-radius: 12px;
  margin-top: 12px;
  background-color: white;
}

.item-text {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #121212;
}

.item-close-button {
  position: absolute;
  top: 24px;
  right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #ececec;
  cursor: pointer;
  border: none;
}

.item-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  
  width: 100%;
  padding: 16px 16px 16px 16px;
  border-radius: 12px;
  background-color: white;
  border: 1px solid #6b6b6b;
  margin-top: 12px;

  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #121212;
}

input.item-input:focus {
  border: 1px solid transparent;
  outline: 2px solid #B84F28;
}

.item-submit-button {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  
  width: 100%;
  height: 56px;
  gap: 14px;
  border-radius: 100px;
  margin-top: 12px;
  padding: 16px 16px 16px 16px;

  cursor: pointer;
  color: #121212;
  background-color: white;
  border: 1px solid #121212;
  
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
}

.cooking-time-dropdown {
  width: 100%;
  height: 60px;
  padding: 12px 16px;
  margin-top: 12px;
  box-sizing: border-box;
  border: 1px solid #6b6b6b;
  border-radius: 12px;
  background-color: white;
  color: #121212;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  cursor: pointer;
  outline: none;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.19313 9.23656C7.63941 9.68284 8.36416 9.68284 8.81044 9.23656L15.6653 2.38171C16.1116 1.93543 16.1116 1.21068 15.6653 0.764397C15.219 0.318118 14.4943 0.318118 14.048 0.764397L8 6.81237L1.95202 0.767967C1.50575 0.321688 0.780988 0.321688 0.334709 0.767967C-0.11157 1.21425 -0.11157 1.939 0.334709 2.38528L7.18956 9.24013L7.19313 9.23656Z" fill="black"/></svg>');
  background-repeat: no-repeat;
  background-position: right 16px center;
  background-size: 16px;
}

.cooking-time-dropdown:focus {
  border: 2px solid #b84f28;
}

.preference-buttons {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: flex-end;
  position: sticky;
  bottom: 16px;
  margin-top: 36px;
}

.preferences-generate-button {
  width: 200px;
  height: 60px;
  padding: 0px 16px;
  border-radius: 100px;
  align-self: flex-end;
  margin-right: 16px;

  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: none;

  font-family: "Quicksand", sans-serif;
  font-size: 18px;
  line-height: 22.5px;
  font-weight: 600;

  color: white;
  background-color: #D8734E;
  box-shadow: 0px 4px 8px 0px #00000040;
}

.preferences-cancel-button {
  width: 120px;
  height: 44px;
  padding: 0px 16px;
  margin-bottom: 16px;
  border-radius: 100px;
  align-self: flex-end;
  margin-right: 16px;

  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: none;

  font-family: "Quicksand", sans-serif;
  font-size: 18px;
  line-height: 22.5px;
  font-weight: 600;

  background-color: white;
  color: #121212;
  box-shadow: 0px 4px 8px 0px #00000040;
}
