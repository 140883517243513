.needs-login {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto !important;
  height: auto !important;
}

.needs-login-dismiss-button {
  margin: 24px 32px 24px 32px;
  align-self: flex-end;
  cursor: pointer;
  svg {
    height: 18px;
    width: 18px;
  }
}

.needs-login-dog {
  max-height: 132px;
  object-fit: contain;
}

.needs-login-title {
  margin: 20px 16px 8px 16px;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  color: #121212;
  text-align: center;
}

.needs-login-subtitle {
  margin: 0px 16px 32px 16px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #424242;
  text-align: center;
}

.needs-login-signup-button {
  width: calc(100% - 32px);
  height: 60px;
  margin: 0px 16px;
  padding: 16px;
  border-radius: 100px;
  box-sizing: border-box;

  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: none;

  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;

  background-color: #D8734E;
  color: white;
  border: none;
}

.needs-login-login-button {
  width: calc(100% - 32px);
  height: 60px;
  margin: 16px 16px 32px 16px;
  padding: 16px;
  border-radius: 100px;
  box-sizing: border-box;

  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: none;

  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;

  background-color: white;
  color: #121212;
  border: 1px solid #424242;
}
