.subscription-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto !important;
  min-height: 100vh;
  height: auto !important;

  @media (min-width: 1000px) {
    border-left: 1px solid #BDBDBD;
    border-right: 1px solid #BDBDBD;
  }
}

.subscription-modal-content {
  display: flex;
  flex-direction: column;
}

.subscription-modal-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin: 24px 0;
}

.subscription-modal-close-button {
  position: absolute;
  top: 24px;
  right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #ececec;
  cursor: pointer;
  border: none;
}