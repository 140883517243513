.lets-go {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto !important;
  min-height: calc(100vh - 84px);

  @media (min-width: 1000px) {
    border-left: 1px solid #BDBDBD;
    border-right: 1px solid #BDBDBD;
  }
}

.lets-go .headerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
  height: 70px;
  padding: 20px 16px 20px 20px;
  border-bottom: 1px solid #BDBDBD;
  z-index: 2;
  background-color: white;
}

.lets-go .header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 20px;
  line-height: 25px;
  font-weight: 700;
}

.lets-go-signup {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}

.lets-go-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.lets-go .contentContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 16px 12px 20px;
  box-sizing: border-box;
}

.lets-go .lets-go-logo {
  width: 100%;
  max-width: 375px;
}

.lets-go .lets-go-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: calc(100% - 48px);
  min-height: 60px;
  padding: 12px 24px;
  border-radius: 100px;
  align-self: center;
  margin-top: 20px;

  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: none;

  font-family: "Quicksand", sans-serif;
  font-size: 18px;
  line-height: 22.5px;
  font-weight: 600;

  color: white;
  background-color: #D8734E;
}
