.newsletter-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: unset !important;
}

.newsletter-header {
  display: flex;
  width: calc(100% - 16px);
  align-self: flex-start;
  cursor: pointer;
  padding: 35px 0 0 16px;
  background-color: rgba(255, 225, 204, 1);

  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.01em;

  @media (min-width: 1000px) {
    padding: 35px 0 35px 35px;
    width: calc(100% - 35px);
  }
}

.newsletter-main-content {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: calc(100% - 32px);
  padding: 0 16px;
  padding-top: 35px;
  background-color: rgba(255, 225, 204, 1);
  min-height: calc(100vh - 250px);

  @media (min-width: 1000px) {
    flex-direction: row;
    padding: 0 100px;
    width: calc(100% - 200px);
    gap: 80px;
  }
}

.newsletter-main-content-left {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
}

.newsletter-main-content-right {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.newsletter-title {
  font-size: 52px;
  font-weight: 700;
  line-height: 65px;
  text-align: left;
}

.newsletter-description {
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  margin-top: 20px;
  text-align: left;
}

.newsletter-image {
  width: 100%;
  max-width: 600px;
  margin-top: 60px;
  object-fit: contain;

  @media (min-width: 1000px) {
    margin-top: unset;
  }
}

.newsletter-success-message {
  margin-top: 40px;

  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.02em;
  text-align: left;
  color: rgba(184, 79, 40, 1);
}

.newsletter-form {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 40px;
  gap: 16px;

  @media (min-width: 1000px) {
    max-width: 375px;
  }
}

.newsletter-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;

  width: 100%;
  padding: 16px 16px 16px 16px;
  border-radius: 12px;
  background-color: white;
  border: 1px solid #6b6b6b;

  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #121212;
}

.newsletter-input:focus {
  border: 1px solid transparent;
  outline: 2px solid #B84F28;
}

.newsletter-cooking-goal {
  width: 100%;
  height: 60px;
  padding: 12px 16px;
  box-sizing: border-box;
  border: 1px solid #6b6b6b;
  border-radius: 12px;
  background-color: white;
  color: #121212;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  cursor: pointer;
  outline: none;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.19313 9.23656C7.63941 9.68284 8.36416 9.68284 8.81044 9.23656L15.6653 2.38171C16.1116 1.93543 16.1116 1.21068 15.6653 0.764397C15.219 0.318118 14.4943 0.318118 14.048 0.764397L8 6.81237L1.95202 0.767967C1.50575 0.321688 0.780988 0.321688 0.334709 0.767967C-0.11157 1.21425 -0.11157 1.939 0.334709 2.38528L7.18956 9.24013L7.19313 9.23656Z" fill="black"/></svg>');
  background-repeat: no-repeat;
  background-position: right 16px center;
  background-size: 16px;
}

.newsletter-cooking-goal:focus {
  border: 2px solid #b84f28;
}

.newsletter-submit-button {
  height: 60px;
  padding: 0px 16px;
  border-radius: 100px;

  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: none;

  font-family: "Quicksand", sans-serif;
  font-size: 18px;
  line-height: 22.5px;
  font-weight: 600;

  color: white;
  background-color: #D8734E;
}

.newsletter-testimonial-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 48px);
  height: 480px;
  background: rgba(0, 120, 129, 1);
  padding: 0 24px;

  @media (min-width: 1000px) {
    flex-direction: row;
    height: 310px;
    gap: 24px;
  }
}

.newsletter-testimonial-image img {
  width: 100px;
  height: 100px;
  object-fit: contain;
}

.newsletter-testimonial-container-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.newsletter-testimonial-quotation-mark {
  width: 24px;
  object-fit: contain;
  align-self: flex-start;
}

.newsletter-testimonial-text {
  max-width: 525px;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  color: white;
}

.newsletter-testimonial-name {
  margin-top: 16px;
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.01em;
  text-align: left;
  color: white;

  @media (max-width: 1000px) {
    display: none
  }
}

.newsletter-testimonial-name-mobile {
  display: none;

  @media (max-width: 1000px) {
    display: flex;
    margin-top: 12px;
    margin-bottom: 40px;
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0.01em;
    text-align: left;
    color: white;
  }
}

.newsletter-whats-included-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: calc(100% - 32px);
  padding: 0 16px;
  max-width: 820px;
  gap: 60px;
  margin-bottom: 80px;
}

.newsletter-whats-included-title {
  margin-top: 80px;
  font-size: 52px;
  font-weight: 700;
  line-height: 65px;
  color: rgba(184, 79, 40, 1);

  @media (max-width: 1000px) {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
  }
}

.newsletter-whats-included-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 110px;
  width: 100%;

  @media (max-width: 1000px) {
    flex-direction: column;
    gap: 40px;
  }
}

.newsletter-whats-included-text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  width: 100%;

  @media (max-width: 1000px) {
    align-items: flex-start;
  }
}

.newsletter-whats-included-text-title {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  text-align: left;
}

.newsletter-whats-included-text-subtitle {
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.02em;
  text-align: left;
}

.newsletter-whats-included-item-container img {
  width: 210px;
  object-fit: contain;
}

.newsletter-footer-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 32px);
  padding: 80px 16px;
  background-color: rgba(255, 225, 204, 1);
}

.newsletter-footer-form-title {
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  text-align: left;
}

.newsletter-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 32px);
  background: rgba(66, 66, 66, 1);
  padding: 40px 16px;
}

.newsletter-footer-social {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.newsletter-footer-social-image-container {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.newsletter-footer-social img {
  width: 34px;
  object-fit: contain;
}

.newsletter-privacy-policy {
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.01em;
  text-align: left;
  color: white;
  text-decoration: underline;
  cursor: pointer;
}
