.profile-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto !important;
  min-height: 100vh;
  height: auto !important;

  @media (min-width: 1000px) {
    border-left: 1px solid #BDBDBD;
    border-right: 1px solid #BDBDBD;
  }
}

.profile-content {
  display: flex;
  flex-direction: column;
}

.profile-header-image {
  object-fit: cover;
  width: 100%;
  max-height: 300px;
}

.profile-user-name {
  margin-top: 32px;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
}

.profile-join-date {
  margin-top: 4px;
  font-size: 14px;
  line-height: 17.5px;
  font-weight: 500;
  color: #414141;
}

.profile-support-header {
  margin: 48px 16px 0px 16px;
  padding-bottom: 16px;
  width: calc(100% - 32px);
  align-self: flex-start;
  text-align: left;
  
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;

  color: #424242;
  border-bottom: 2px solid #6B6B6B;
}

.profile-contact-us-button {
  display: flex;
  align-items: center;
  align-self: flex-start;
  justify-content: space-between;
  box-sizing: border-box;
  margin: 16px 16px 16px 16px;
  padding-left: 8px;
  padding-right: 16px;
  padding-bottom: 16px;
  width: calc(100% - 32px);
  
  text-align: left;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;

  color: #121212;
  border-bottom: 1px solid #6B6B6B;
  text-decoration: none;
}

.profile-subscription-header {
  margin: 48px 16px 0px 16px;
  padding-bottom: 16px;
  width: calc(100% - 32px);
  align-self: flex-start;
  text-align: left;
  
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;

  color: #424242;
  border-bottom: 2px solid #6B6B6B;
}

.profile-choose-a-plan-button {
  display: flex;
  align-items: center;
  align-self: flex-start;
  justify-content: space-between;
  box-sizing: border-box;
  margin: 16px 16px 16px 16px;
  padding-left: 8px;
  padding-right: 16px;
  padding-bottom: 16px;
  width: calc(100% - 32px);
  
  text-align: left;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;

  color: #121212;
  border-bottom: 1px solid #6B6B6B;
  text-decoration: none;
  cursor: pointer;
}

.profile-sign-out-button {
  width: calc(100% - 32px);
  height: 60px;
  margin: 16px 16px;
  padding: 16px;
  border-radius: 100px;
  box-sizing: border-box;

  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: none;

  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;

  background-color: white;
  color: #121212;
  border: 1px solid #424242;
}
