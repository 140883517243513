.recipe-list-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto !important;
  min-height: 100vh;
  height: auto !important;

  @media (min-width: 1000px) {
    border-left: 1px solid #BDBDBD;
    border-right: 1px solid #BDBDBD;
  }
}

.recipe-list-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 70px;
  padding: 20px 16px 20px 20px;
  border-bottom: 1px solid #bdbdbd;
  z-index: 2;
  background-color: white;
  font-size: 20px;
  line-height: 25px;
  font-weight: 700;
}

.recipe-list-signup {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}

.recipe-list-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
}

.recipe-list-recipe {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  width: calc(100% - 32px);
  margin: 0px 16px;
  padding: 24px 0px;
  border-bottom: 1px solid #bdbdbd;
  cursor: pointer;
}

.recipe-list-recipe-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.recipe-list-recipe-title {
  font-family: 'Plush-trial', sans-serif;
  font-size: 24px;
  line-height: 36px;
  font-style: italic;
  font-weight: 700;
  margin-left: 8px;
  margin-bottom: 8px;
  margin-right: 32px;
  text-align: left;
}

.recipe-list-recipe-icon {
  position: absolute;
  margin-top: 12px;
  right: 32px;

  @media (min-width: 1000px) {
    right: calc(50% - 500px + 32px)
  }
}

.recipe-list-recipe-description {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #6b6b6b;
  text-align: left;
  margin-left: 8px;
}

.recipe-list-recipe-created-date {
  font-size: 14px;
  line-height: 17.5px;
  font-weight: 500;
  margin-top: 18px;
  margin-left: 8px;
  margin-bottom: 8px;
  color: #6B6B6B;
}

.recipe-list-recipe-footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
  font-size: 14px;
  line-height: 17.5px;
  font-weight: 700;
  margin-top: 32px;
  margin-left: 8px;
  margin-bottom: 8px;
  color: #121212;
}

.recipe-list-generate-more {
  width: calc(100% - 32px);
  height: 56px;
  margin-top: 24px;
  margin-bottom: 24px;
  border-radius: 100px;

  align-self: center;
  text-align: center;
  text-decoration: none;
  cursor: pointer;

  font-size: 16px;
  line-height: 20px;
  font-weight: 700;

  background-color: white;
  color: #121212;
  border: 1px solid #424242;
}

.recipe-list-tip {
  padding: 0 16px 32px 16px;
  height: 200px;
  object-fit: contain;
  width: calc(100vw - 32px);
  align-self: center;
  cursor: pointer;
}

.recipe-list-edit-preferences {
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 100px;
  width: 200px;
  height: 60px;
  min-height: 60px;
  padding: 0px 16px;
  border-radius: 100px;
  align-self: flex-end;
  margin-right: 16px;
  margin-bottom: 16px;

  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: none;

  font-family: "Quicksand", sans-serif;
  font-size: 18px;
  line-height: 22.5px;
  font-weight: 600;

  color: white;
  background-color: #D8734E;
  box-shadow: 0px 4px 8px 0px #00000040;
}
