.recipe-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto !important;
  height: unset !important;

  @media (min-width: 1000px) {
    min-height: 100vh;
    border-left: 1px solid #BDBDBD;
    border-right: 1px solid #BDBDBD;
  }
}

.recipe-tabs {
  position: sticky;
  top: 0px;
  background-color: white;
  border-bottom: 1px solid #BDBDBD;
}

.recipe-title {
  font-family: 'Plush-trial', sans-serif;
  font-size: 24px;
  line-height: 36px;
  font-style: italic;
  font-weight: 700;
  margin-top: 40px;
  text-align: center;
}

.recipe-description {
  font-family: "Quicksand", sans-serif;
  font-size: 14px;
  line-height: 17.5px;
  font-weight: 500;
  color: #6b6b6b;
  margin-top: 8px;
  margin-left: 32px;
  margin-right: 32px;
  margin-bottom: 24px;
  text-align: center;
}

.recipe-ingredient-list {
  margin: 0 16px;
  padding: 0;
  border-bottom: 1px solid #bdbdbd;
}

.recipe-ingredient-item {
  display: flex;
  flex-direction: row;
  padding: 16px 0;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #bdbdbd;
}

.recipe-ingredient-item:first-child {
  border-top: none;
}

.recipe-ingredient-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 37px;
}

.recipe-ingredient-name {
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  color: #121212;
  text-align: left;
}

.recipe-ingredient-detail {
  font-family: "Quicksand", sans-serif;
  font-size: 14px;
  line-height: 17.5px;
  font-weight: 500;
  color: #6b6b6b;
  text-align: left;
}

.recipe-ingredient-quantity {
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #6b6b6b;
  text-align: right;
}

.recipe-ingredient-disclaimer-title {
  margin-top: 32px;
  margin-left: 16px;
  margin-bottom: 8px;
  font-family: "Quicksand", sans-serif;
  font-size: 14px;
  line-height: 17.5px;
  font-weight: 700;
  color: #6b6b6b;
  text-align: left;
}

.recipe-ingredient-disclaimer-description {
  margin-top: 8px;
  margin-left: 16px;
  margin-bottom: 8px;
  font-family: "Quicksand", sans-serif;
  font-size: 14px;
  line-height: 17.5px;
  font-weight: 500;
  color: #6b6b6b;
  text-align: left;
}

.recipe-instruction-list {
  margin: 32px 16px 0px 16px;
  padding: 0;
}

.recipe-instruction-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 24px;
}

.recipe-instruction-number {
  font-family: "Quicksand", sans-serif;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  min-width: 30px;
  text-align: left;
  color: #b84f28;
}

.recipe-instruction-text {
  font-family: "Quicksand", sans-serif;
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  color: #393939;
  text-align: left;
}

.recipe-close-button-container {
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  gap: 16px;
  bottom: 80px;
  width: 144px;
}

.recipe-close-button {
  width: calc(100% - 32px);
  height: 60px;
  margin: 16px 16px;
  padding: 16px;
  border-radius: 100px;
  box-sizing: border-box;

  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: none;

  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;

  background-color: white;
  color: #121212;
  border: none;
  box-shadow: 0px 4px 8px 0px #00000052;
}

.recipe-save-button {
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  bottom: 16px;
  width: 200px;
  height: 60px;
  min-height: 60px;
  padding: 0px 16px;
  border-radius: 100px;
  align-self: flex-end;
  margin-right: 16px;
  margin-bottom: 16px;

  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: none;

  font-family: "Quicksand", sans-serif;
  font-size: 18px;
  line-height: 22.5px;
  font-weight: 600;

  color: white;
  background-color: #D8734E;
  box-shadow: 0px 4px 8px 0px #00000040;
}

.recipe-unsave-button {
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  bottom: 16px;
  width: 220px;
  height: 60px;
  min-height: 60px;
  padding: 0px 16px;
  border-radius: 100px;
  align-self: flex-end;
  margin-right: 16px;
  margin-bottom: 16px;

  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: none;

  font-family: "Quicksand", sans-serif;
  font-size: 18px;
  line-height: 22.5px;
  font-weight: 600;

  color: black;
  background-color: white;
  border: 1px solid #424242;
  box-shadow: 0px 4px 8px 0px #00000040;
}

.nutrition-facts-container {
  margin: 32px 16px 0px 16px;
  padding: 0;
}

.nutrition-facts-title {
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  margin-bottom: 16px;
}

.nutrition-facts {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 20px;
  background-color: #ECECEC;
  padding: 20px 16px 20px 16px;
}

.nutrition-facts-row,
.nutrition-facts-inner-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.nutrition-facts-inner-row {
  margin-left: 16px;
  width: calc(100% - 16px);
}

.nutrition-facts-heading {
  font-family: "Quicksand", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0.02em;
  text-align: left;
}

.nutrition-facts-heading-values {
  display: flex;
  flex-direction: row;
  gap: 12px;
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
}

.nutrition-facts hr {
  width: 100%;
  border-bottom: 1px solid #6B6B6B;
}

.nutrition-facts-result-values {
  display: grid;
  grid-template-columns: 80px 40px;
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
}

.nutrition-facts-subvalue,
.nutrition-facts-percent {
  text-align: right;
}

.nutrition-facts-row .nutrition-facts-label {
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.02em;
  text-align: left;
}