.cookbook-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto !important;
  min-height: 100vh;
  height: auto !important;

  @media (min-width: 1000px) {
    border-left: 1px solid #BDBDBD;
    border-right: 1px solid #BDBDBD;
  }
}

.cookbook-header {
  margin: 20px;
  font-size: 20px;
  line-height: 25px;
  font-weight: 700;
  text-align: left;
}

.cookbook-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.cookbook-tabs {
  padding: 0 84px;
  border-bottom: 1px solid #BDBDBD;
}