.signup-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto !important;
  height: unset !important;
  
  @media (min-width: 1000px) {
    min-height: 100vh;
    border-left: 1px solid #BDBDBD;
    border-right: 1px solid #BDBDBD;
  }
}

.close-button {
  margin: 32px;
  align-self: flex-end;
  cursor: pointer;
  svg {
    height: 18px;
    width: 18px;
  }
}

.signup-header {
  margin-top: 8px;
  margin-bottom: 40px;
  font-family: "Quicksand", sans-serif;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
}

.signup-input {
  box-sizing: border-box; 
  height: 60px;
  width: calc(100% - 32px);
  margin: 0 16px 24px 16px;
  padding: 12px 16px 12px 16px;
  
  border-radius: 12px;
  border: 1px solid #6B6B6B;

  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #121212;
}

input.signup-input::placeholder {
  color: #6B6B6B;
}

input.signup-input:focus {
  border: 1px solid transparent;
  outline: 2px solid #B84F28;
}

.create-account-button {
  width: calc(100% - 32px);
  height: 60px;
  margin: 0px 16px;
  padding: 16px;
  border-radius: 100px;
  box-sizing: border-box;

  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: none;

  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;

  background-color: #D8734E;
  color: white;
}

.signup-or-divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 40px 16px;
}

.signup-or-divider::before,
.signup-or-divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #6B6B6B;
}

.signup-or-divider:not(:empty)::before {
  margin-right: 12px;
}

.signup-or-divider:not(:empty)::after {
  margin-left: 12px;
}

.signup-or-divider span {
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  color: #6B6B6B;
}

.google-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: calc(100% - 32px);
  height: 60px;
  margin: 0px 16px;
  padding: 16px;
  border-radius: 100px;
  box-sizing: border-box;

  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: none;

  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;

  background-color: white;
  color: #121212;
  border: 1px solid #424242;
}

.signup-disclaimer {
  margin-top: 40px;
  padding: 0 54px;

  font-family: "Quicksand", sans-serif;
  font-size: 14px;
  line-height: 17.5px;
  font-weight: 500;
}

.signup-disclaimer-link {
  font-weight: 700;
  cursor: pointer;
}

.signup-login-message {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 6px;
  margin-top: 40px;
  margin-bottom: 60px;

  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700; 
}

.signup-login-button {
  color: #B84F28;
  cursor: pointer;
}
